import React from 'react'
import './app.sass'

function App() {
  return (
    <div className="container">
      <main>
        <h1 className="title">
          Next Chapter
        </h1>

        <p className="description">
          Product design &amp; development
        </p>
        
        <p className="contact">
          <a href="mailto:hello@nextchapter.dev">
            lucian@nextchapter.dev
          </a>
        </p>

        {/*
        <h2>Clients</h2>
        <div className="grid">
          <a href="https://nextjs.org/docs" className="card">
            <h3>Fomo &rarr;</h3>
            <p>https://sl.linkedin.com/loucyan Lucian Kranz</p>
          </a>

          <a href="https://nextjs.org/learn" className="card">
            <h3>Picture It &rarr;</h3>
            <p>Fomo increases trust, credibility, and sales with live social proof</p>
          </a>  
        
          <a href="https://nextjs.org/docs" className="card">
            <h3>Datafund &rarr;</h3>
            <p>https://sl.linkedin.com/loucyan Lucian Kranz</p>
          </a>

          <a href="https://nextjs.org/learn" className="card">
            <h3>Gramatik &rarr;</h3>
            <p>Fomo increases trust, credibility, and sales with live social proof</p>
          </a>  
        </div>
        */}
      </main>

      <footer>
        <div>
          <p>
            *
          </p>
        </div>
        
        <div>
          <span>
            &copy; {new Date().getFullYear()} Next Chapter
          </span>
        </div>
      </footer>
    </div>
  )
}

export default App
